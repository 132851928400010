import revive_payload_client_kEMDSykibu from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_me2wcenb6qbgcs4uug6qrroxke/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_H5r65PhiyJ from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_me2wcenb6qbgcs4uug6qrroxke/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8kD5aQiNrO from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_me2wcenb6qbgcs4uug6qrroxke/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_TsuZvnJmjp from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_me2wcenb6qbgcs4uug6qrroxke/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_RRCrMNa8Wz from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_me2wcenb6qbgcs4uug6qrroxke/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_82t2Kq6d7c from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_me2wcenb6qbgcs4uug6qrroxke/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_prnreXqTfd from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_me2wcenb6qbgcs4uug6qrroxke/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_0s4Zs4HPSh from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_me2wcenb6qbgcs4uug6qrroxke/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_hpdAyrvUDP from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.4_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/rialtic/workflows/console-ui/apps/content/.nuxt/components.plugin.mjs";
import prefetch_client_Bba2A1WGII from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_me2wcenb6qbgcs4uug6qrroxke/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/rialtic/workflows/console-ui/apps/content/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ORqbCYedMo from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.0.11_magicast@0.3.5_rollup@4.24.4_vite@6.0_jd7uatso4o6q3a5bv5xinsaare/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import unocss_MzCDxu9LMj from "/builds/rialtic/workflows/console-ui/apps/content/.nuxt/unocss.mjs";
import pendo_hkesixFq4o from "/builds/rialtic/workflows/console-ui/packages/nuxt-pendo/src/runtime/pendo.ts";
import datadog_JKmUALlR6H from "/builds/rialtic/workflows/console-ui/packages/nuxt-datadog/src/runtime/datadog.ts";
import plugin_526V8oWKGJ from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/plugin.ts";
import formkitPlugin_pZqjah0RUG from "/builds/rialtic/workflows/console-ui/apps/content/.nuxt/formkitPlugin.mjs";
import plugin_PXQeuxRKoc from "/builds/rialtic/workflows/console-ui/packages/ui/src/runtime/plugin.ts";
import plugin_MwzO4yGucH from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.4_typesc_gummvr5zvfnqdrgh7t4kjut3l4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
export default [
  revive_payload_client_kEMDSykibu,
  unhead_H5r65PhiyJ,
  router_8kD5aQiNrO,
  payload_client_TsuZvnJmjp,
  navigation_repaint_client_RRCrMNa8Wz,
  check_outdated_build_client_82t2Kq6d7c,
  view_transitions_client_prnreXqTfd,
  chunk_reload_client_0s4Zs4HPSh,
  plugin_vue3_hpdAyrvUDP,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Bba2A1WGII,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ORqbCYedMo,
  unocss_MzCDxu9LMj,
  pendo_hkesixFq4o,
  datadog_JKmUALlR6H,
  plugin_526V8oWKGJ,
  formkitPlugin_pZqjah0RUG,
  plugin_PXQeuxRKoc,
  plugin_MwzO4yGucH
]